import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import HorizontalNav from "../navigation/HorizontalNav";
import GlobalFedDropdownContext from "../../contexts/GlobalFedDropdownContext";
import EmailNotifications from "./EmailNotifications";

const EmailNotificationsDashboard = withRouter(() => {
  const { dispatch: globalFedDropdownDispatch } = useContext(GlobalFedDropdownContext);
  // Hide Global Fed Team dropdown
  useEffect(() => {
    globalFedDropdownDispatch({
      type: "setShowingState",
      showingState: false
    });
  }, [globalFedDropdownDispatch]);

  return (
    <div className="d-flex w-100 h-100">
      <HorizontalNav title="Email Notifications"></HorizontalNav>
      <EmailNotifications className="d-flex h-100" />
    </div>
  );
});

export default EmailNotificationsDashboard;
