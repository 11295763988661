import React, { useReducer } from "react";
import UserManagementContext from "../contexts/UserManagementContext";
import PropTypes from "prop-types";
import _ from "lodash";
import UserRoleConstants from "../constants/UserRoleConstants";

const initialState = {
  users: [],
  displayedUsers: [],
  userFilter: "",
  countryAssignmentUpdates: [],
  countries: [],
  orgs: [],
  orgAssignmentUpdates: [],
  roleOptions: [],
  roleAssignmentUpdates: [],
  sortFilters: {
    direction: "ASC",
    key: "lastName"
  },
  notificationOptions: [],
  notifications: [],
  notificationAssignmentUpdates: []
};

const UserManagementContextProvider = ({ children }) => {
  const sort = (users, searchFilters) => {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(users, [user => _.toLower(user["lastName"]), user => _.toLower(user["firstName"])]);
    } else if (direction === "ASC") {
      if (searchFilters.key === "roles") {
        return _.sortBy(users, [user => user[searchFilters.key]]);
      } else if (searchFilters.key === "email") {
        return _.sortBy(users, [user => user[searchFilters.key]]).reverse();
      } else if (searchFilters.key === "lastName") {
        return _.sortBy(users, [user => _.toLower(user["lastName"]), user => _.toLower(user["firstName"])]);
      }
      return _.sortBy(users, [user => _.toLower(user[searchFilters.key])]);
    } else {
      if (searchFilters.key === "roles") {
        return _.sortBy(users, [user => user[searchFilters.key]]).reverse();
      } else if (searchFilters.key === "email") {
        return _.sortBy(users, [user => user[searchFilters.key]]);
      } else if (searchFilters.key === "lastName") {
        return _.sortBy(users, [user => _.toLower(user["lastName"]), user => _.toLower(user["firstName"])]).reverse();
      }
      return _.sortBy(users, [user => _.toLower(user[searchFilters.key])]).reverse();
    }
  };

  const formatForComparison = str => (!str ? "" : str.replace(/[^A-Za-z ]+/g, "").toLowerCase());

  const formatRolesForComparison = ({ role }) => {
    switch (role) {
      case UserRoleConstants.FED_TEAM_USER:
        return "Fed Team User".toLowerCase();
      case UserRoleConstants.FED_TEAM_ADMIN:
        return "Fed Team Admin".toLowerCase();
      case UserRoleConstants.BOC_ADMIN:
        return "BOC Admin".toLowerCase();
      case UserRoleConstants.BOC_VIEWER:
        return "BOC Viewer".toLowerCase();
      default:
        return "";
    }
  };

  const filterUsers = (users, sortFilters, userFilter) => {
    const toDisplay = users.filter(user => {
      if (userFilter === "") return true;
      return (
        _.startsWith(formatForComparison(user.lastName), formatForComparison(userFilter)) ||
        _.startsWith(formatForComparison(user.firstName), formatForComparison(userFilter)) ||
        _.startsWith(formatForComparison(user.lastName + user.firstName), formatForComparison(userFilter)) ||
        _.startsWith(formatForComparison(user.email), formatForComparison(userFilter)) ||
        (user.role && _.startsWith(formatRolesForComparison(user.role), formatForComparison(userFilter)))
      );
    });
    return sort(toDisplay, sortFilters);
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "setUsers":
        return {
          ...state,
          users: action.users,
          displayedUsers: filterUsers(action.users, state.sortFilters, state.userFilter)
        };
      case "initializeCountryAssignments":
        return { ...state, countryAssignmentUpdates: [...action.assignments] };
      case "updateCountryAssignments":
        state.countryAssignmentUpdates.forEach(entry => {
          if (entry.accountId === action.accountId) {
            entry.countryIds = action.countryIds;
          }
        });
        return { ...state, countryAssignmentUpdates: [...state.countryAssignmentUpdates] };
      case "updateRoleAssignments":
        state.roleAssignmentUpdates.forEach(entry => {
          if (entry.accountId === action.accountId) {
            entry.roleId = action.roleId;
          }
        });
        return { ...state, roleAssignmentUpdates: [...state.roleAssignmentUpdates] };
      case "initializeRoleAssignments":
        return { ...state, roleAssignmentUpdates: [...action.roleAssignments] };
      case "initializeOrgAssignments":
        return { ...state, orgAssignmentUpdates: [...action.orgAssignments] };
      case "updateOrgAssignments":
        state.orgAssignmentUpdates.forEach(entry => {
          if (entry.accountId === action.accountId) {
            entry.orgId = action.orgId;
          }
        });
        return { ...state, orgAssignmentUpdates: [...state.orgAssignmentUpdates] };
      case "initializeNotificationAssignments":
        return { ...state, notificationAssignmentUpdates: [...action.notificationAssignments] };
      case "updateNotificationAssignmentUpdates":
        state.notificationAssignmentUpdates.forEach(entry => {
          if (entry.accountId === action.accountId) {
            entry.notificationTypeIds = action.notificationTypeIds;
          }
        });
        return { ...state, notificationAssignmentUpdates: [...state.notificationAssignmentUpdates] };
      case "setCountries":
        return {
          ...state,
          countries: [
            ...action.countries
              .sort((a, b) => {
                return a?.fedTeamName?.localeCompare(b?.fedTeamName);
              })
              .map(country => ({
                ...country,
                value: country?.countryId,
                label: country?.fedTeamName
              }))
          ]
        };
      case "setOrgs":
        return {
          ...state,
          orgs: action.orgs
        };
      case "setUserFilter":
        return {
          ...state,
          userFilter: action.userFilter,
          displayedUsers: filterUsers(state.users, state.sortFilters, action.userFilter)
        };
      case "setSortFilters":
        let sortFilters = {
          direction: action.direction,
          key: action.col
        };
        return {
          ...state,
          sortFilters: sortFilters,
          displayedUsers: filterUsers(state.users, sortFilters, state.userFilter)
        };
      case "setRoleOptions":
        return {
          ...state,
          roleOptions: action.roleOptions
        };
      case "setNotificationOptions":
        return {
          ...state,
          notificationOptions: action.notificationOptions
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <UserManagementContext.Provider value={{ state, dispatch }}>{children}</UserManagementContext.Provider>;
};

UserManagementContextProvider.propTypes = {
  children: PropTypes.object
};

export default UserManagementContextProvider;
