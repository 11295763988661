import React from "react";
import PropTypes from "prop-types";

const ProfileHistoryActionDateFormatter = ({ row }) => {
  return <>{row.actionDateAsString}</>;
};

ProfileHistoryActionDateFormatter.propTypes = {
  row: PropTypes.shape({
    actionDateAsString: PropTypes.number.isRequired
  }).isRequired
};

export default ProfileHistoryActionDateFormatter;
