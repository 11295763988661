import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import WBCModal from "../../elements/WBCModal";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import { BestSelect } from "best-common-react";
import { MAIN, QUALIFYING, QUALIFYING_ID } from "../../../constants/TournamentTypeConstants";
import { WBCPrimaryButton } from "../../elements/WBCPrimaryButton";
import { useAlert } from "../../hooks/useAlert";
import useDownloadFile from "../../hooks/useDownloadFile";
import ExportApi from "../../../httpClients/ExportApi";

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

BodyContent.displayName = "BodyContent";

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  & button {
    margin-left: 0.5rem;
  }
`;

FooterContent.displayName = "FooterContent";

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  width: 200px;
`;

const ExportFinalRostersModal = ({ isOpen, setIsOpen }) => {
  const showAlert = useAlert();
  const downloadFile = useDownloadFile();

  const [exportOptions, setExportOptions] = useState([]);
  const [selectedExportOption, setSelectedExportOption] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  const handleExportFinalRosters = () => {
    setIsExporting(true);
    if (selectedExportOption?.value) {
      ExportApi.exportFinalRosters(selectedExportOption.value.year, selectedExportOption.value.tournamentTypeId)
        .then((res, headers) => {
          downloadFile(res, headers);
          setIsOpen(false);
        })
        .catch(error => {
          showAlert("Error exporting final rosters", "danger");
        })
        .finally(() => {
          setIsExporting(false);
        });
    }
  };

  const handleSelectExportOption = option => {
    setSelectedExportOption(option);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const opts = [];
    ExportApi.getExportFinalRosterOptions()
      .then(res => {
        res.forEach(option => {
          const tournamentType = option.tournamentTypeId === QUALIFYING_ID ? QUALIFYING : MAIN;
          opts.push({
            label: `${option.year} - ${tournamentType.toUpperCase()}`,
            value: option
          });
        });
        setExportOptions(opts);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <WBCModal headerText="Export Final Rosters" isOpen={isOpen} toggleModal={toggleModal} title="Export Final Rosters">
      <BodyContent>
        <div className="d-flex row px-2">
          <p className="w-100">Select tournament to export final rosters for</p>
          <div className="d-flex w-100">
            <SelectContainer>
              <BestSelect
                name="exportOptionsDropdown"
                value={selectedExportOption}
                options={exportOptions}
                onChange={handleSelectExportOption}
              />
            </SelectContainer>
          </div>
        </div>
      </BodyContent>
      <FooterContent>
        <WBCPrimaryButton disabled={isExporting} onClick={handleExportFinalRosters}>
          Export
        </WBCPrimaryButton>
        <WBCPrimaryOutlinedButton onClick={toggleModal}>Cancel</WBCPrimaryOutlinedButton>
      </FooterContent>
    </WBCModal>
  );
};

ExportFinalRostersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  loadChronicConditionsByYearFn: PropTypes.func.isRequired
};

export default ExportFinalRostersModal;
