import { useContext, useCallback } from "react";
import GlobalModalContext from "../../contexts/GlobalModalContext";

export const useError = () => {
  const globalModalContext = useContext(GlobalModalContext);
  const { dispatch } = globalModalContext;

  return useCallback(
    (messages, overrideFn = null, closeCallback = () => {}) => {
      dispatch({
        type: "openErrorModal",
        content: Array.isArray(messages) ? messages : messages?.split("\n"),
        overrideFn,
        closeCallback
      });
    },
    [dispatch]
  );
};
