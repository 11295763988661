import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BestSelect, Input } from "best-common-react";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import { isCurrentOrPastTournamentYear } from "../../../utils/WBCYearPredicates";
import ChronicConditionsContext from "../../../contexts/ChronicConditionsContext";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import AddChronicConditionPlayerModal from "./AddChronicConditionPlayerModal";
import AuthContext from "../../../contexts/AuthContext";

const FiltersContainer = styled.div.attrs(() => ({
  className: "d-flex flex-row justify-content-start align-items-center"
}))`
  @media (max-width: 767px) {
    flex-direction: column;
  }
  margin-bottom: 1rem;
`;

const Filter = styled.div.attrs(() => ({
  className: "col-xs col-md-3 pl-0"
}))`
  @media (max-width: 767px) {
    margin-bottom: 0.75rem;
  }
`;
const TextContainer = styled.div.attrs(() => ({}))`
  margin-right: 2rem;
  color: grey;
  font-style: italic;
  @media (max-width: 767px) {
    margin-bottom: 0.75rem;
  }
`;

const ActionsContainer = styled.div.attrs(props => ({}))`
  cursor: ${props => (props.isActive ? "pointer" : "not-allowed")};
  margin-left: 1rem;
  color: ${props => (props.isActive ? "blue" : "grey")};
`;

ActionsContainer.propTypes = {
  isActive: PropTypes.bool
};

const ChronicConditionsHeader = ({ loadChronicConditionsByYearFn }) => {
  const { isBOCadmin } = useContext(AuthContext).state;
  const { wbcYears, selectedWbcYear, changeSelectedYear } = useContext(WBCYearsContext);
  const {
    nameSearchFilter,
    setNameSearchFilter,
    insuranceStatuses,
    insuranceStatusFilter,
    setInsuranceStatusFilter,
    selectedChronicConditionIds,
    setActionSelected,
    insuranceActions
  } = useContext(ChronicConditionsContext);

  const [addPlayerModalOpen, setAddPlayerModalOpen] = useState(false);

  const yearOptions = wbcYears.filter(isCurrentOrPastTournamentYear);

  const handleStatusChange = status => setInsuranceStatusFilter(status);

  const handleWBCYearChange = useCallback(({ year }) => changeSelectedYear(year), [changeSelectedYear]);

  const handleNameSearchFilter = search => setNameSearchFilter(search?.target?.value);

  const handleActionClicked = action => () => {
    setActionSelected(action);
  };

  return (
    <>
      <FiltersContainer>
        <Filter>
          <BestSelect
            name="yearDropdown"
            value={selectedWbcYear}
            options={yearOptions}
            onChange={handleWBCYearChange}
          />
        </Filter>
        <Filter>
          <BestSelect
            name="insuranceStatusDropdown"
            value={insuranceStatusFilter}
            options={insuranceStatuses}
            onChange={handleStatusChange}
          />
        </Filter>
        <Filter>
          <Input placeholder="type to search" value={nameSearchFilter} onChange={handleNameSearchFilter}></Input>
        </Filter>
        {isBOCadmin && (
          <Filter>
            <WBCPrimaryOutlinedButton onClick={() => setAddPlayerModalOpen(true)}>Add Player</WBCPrimaryOutlinedButton>
            <AddChronicConditionPlayerModal
              isOpen={addPlayerModalOpen}
              setIsOpen={setAddPlayerModalOpen}
              loadChronicConditionsByYearFn={loadChronicConditionsByYearFn}
            />
          </Filter>
        )}
      </FiltersContainer>
      {isBOCadmin && (
        <FiltersContainer>
          <TextContainer>{selectedChronicConditionIds?.length || "0"} selected</TextContainer>
          {insuranceActions.map(
            action =>
              action.action !== "Insurance Review" && (
                <ActionsContainer isActive={action.isActive} onClick={handleActionClicked(action)} key={action?.id}>
                  {action.action}
                </ActionsContainer>
              )
          )}
        </FiltersContainer>
      )}
    </>
  );
};

ChronicConditionsHeader.propTypes = {
  loadChronicConditionsByYearFn: PropTypes.func.isRequired
};

export default ChronicConditionsHeader;
