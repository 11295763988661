import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import PositionsContext from "../../contexts/PositionsContext";
import WBCBaseballInfoApi from "../../httpClients/WBCBaseballInfoApi";
import OrgsContext from "../../contexts/OrgsContext";
import ClubsContext from "../../contexts/ClubsContext";
import AuthContext from "../../contexts/AuthContext";
import LanguageContext from "../../contexts/LanguageContext";
import { useAlert } from "../../components/hooks/useAlert";

const BaseballContextInitializer = ({ children }) => {
  // contexts
  const authContext = useContext(AuthContext);
  const positionsContext = useContext(PositionsContext);
  const orgsContext = useContext(OrgsContext);
  const clubsContext = useContext(ClubsContext);
  const languageContext = useContext(LanguageContext);
  const showAlert = useAlert();

  // variables
  const { loggedIn } = authContext.state;
  const { dispatch: orgDispatch } = orgsContext;
  const { dispatch: clubDispatch } = clubsContext;
  const { dispatch: positionDispatch } = positionsContext;
  const { dispatch: languageDispatch } = languageContext;

  const mapPosition = (posIdToName, posIdToAbbrev, posAbbrevToId) => {
    return pos => {
      posIdToName[pos.positionId] = pos.positionName;
      posIdToAbbrev[pos.positionId] = pos.positionAbbrv;
      posAbbrevToId[pos.positionAbbrv] = pos.positionId;
      return {
        value: pos.positionId,
        label: pos.positionAbbrv,
        name: pos.positionName,
        orderNum: pos.orderNum,
        playerType: pos.playerType
      };
    };
  };

  useEffect(() => {
    if (loggedIn) {
      const posIdToName = {};
      const posIdToAbbrev = {};
      const posAbbrevToId = {};

      WBCBaseballInfoApi.getAllPositions()
        .then(positions => {
          const playerPositions = positions.playerPositions.filter(pos => pos.positionName !== "Pitcher");
          const profilePositions = positions.profilePositions.filter(pos => pos.positionName !== "Pitcher");
          const allPositions = positions.allPositions.filter(pos => pos.positionName !== "Pitcher");
          const staffPositions = allPositions.filter(pos =>
            ["UN", "ATC", "CC", "MA", "CO"].includes(pos.positionAbbrv)
          );

          const allPositionsDropdown = allPositions.map(mapPosition(posIdToName, posIdToAbbrev, posAbbrevToId));
          const playerPositionsDropdown = playerPositions.map(mapPosition(posIdToName, posIdToAbbrev, posAbbrevToId));
          const profilePositionsDropdown = profilePositions.map(mapPosition(posIdToName, posIdToAbbrev, posAbbrevToId));
          const staffPositionsDropdown = staffPositions.map(mapPosition(posIdToName, posIdToAbbrev, posAbbrevToId));

          positionDispatch({
            type: "initialize",
            allPositionsDropdown,
            playerPositionsDropdown,
            profilePositionsDropdown,
            staffPositionsDropdown,
            posIdToName,
            posIdToAbbrev,
            posAbbrevToId
          });
        })
        .catch(response => showAlert(`${response.toString()}, while loading clubs`, "danger"));

      const orgIdToCode = {};

      WBCBaseballInfoApi.getAllOrgs().then(orgs => {
        orgs.sort((a, b) => {
          return a?.orgCode?.localeCompare(b?.orgCode);
        });

        let orgsDropdown = orgs.map(org => {
          if (org.orgCode.toLowerCase() === "no") org.orgCode = "NONE";
          orgIdToCode[org.orgId] = org.orgCode;
          return { value: org.orgId, label: org.orgCode, name: org.orgName };
        });
        // alphabetize orgs

        orgsDropdown = orgsDropdown.filter(org => org.label !== "BOC" && org.label !== "MSB");
        orgDispatch({ type: "setOrgs", orgs });
        orgDispatch({ type: "initialize", orgsDropdown, orgIdToCode });
      });

      const clubIdToName = {};

      WBCBaseballInfoApi.getActiveClubs()
        .then(clubs => {
          const clubsDropdown = clubs.map(club => {
            clubIdToName[club.clubId] = club.clubName;
            return {
              value: club.clubId,
              label: club.clubName,
              shortName: club.clubShortName,
              leagueId: club.leagueId,
              sortOrder: club.sortOrder
            };
          });

          // sort clubs by sort order then by club name
          clubsDropdown.sort((a, b) => {
            if (a.sortOrder === b.sortOrder) {
              return a?.label?.localeCompare(b?.label);
            }
            if (a.sortOrder === null) {
              return 1;
            } else if (b.sortOrder === null) {
              return -1;
            }
            return a.sortOrder - b.sortOrder;
          });

          clubs.sort((a, b) => {
            if (a.sortOrder === b.sortOrder) {
              return a?.clubName?.localeCompare(b?.clubName);
            }
            if (a.sortOrder === null) {
              return 1;
            } else if (b.sortOrder === null) {
              return -1;
            }
            return a.sortOrder - b.sortOrder;
          });

          clubDispatch({ type: "setClubs", clubs });
          clubDispatch({ type: "initialize", clubsDropdown, clubIdToName });
        })
        .catch(response => showAlert(`${response.toString()}, while loading orgs`, "danger"));

      const languageIdToName = {};
      WBCBaseballInfoApi.getAllLanguages().then(languages => {
        let languageDropdown = languages.map(language => {
          languageIdToName[language.languageId] = language.description;
          return { value: language.languageId, label: language.description };
        });

        languageDispatch({ type: "setLanguages", languages });
        languageDispatch({ type: "initialize", languageDropdown, languageIdToName });
      });
    }
  }, [loggedIn, positionDispatch, orgDispatch, clubDispatch, languageDispatch, showAlert]);

  return <div>{children}</div>;
};

BaseballContextInitializer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default BaseballContextInitializer;
