import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import CountryContext from "../../contexts/CountryContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import WBCTeamApi from "../../httpClients/WBCTeamApi";
import { useAlert } from "../../components/hooks/useAlert";

//Ideally I'd like that all contexts are initialized in one place
//on their own for what the app is going to need globally
const ContextProviderInitializer = ({ children }) => {
  const countryContext = useContext(CountryContext);
  const { selectedYear } = useContext(WBCYearsContext);
  const showAlert = useAlert();

  const { dispatch: countryDispatch } = countryContext;

  useEffect(() => {
    if (selectedYear) {
      WBCTeamApi.getCountries()
        .then(countries => {
          const countryIdToName = {};
          const countryIdToCode = {};
          const countryIdToDialCode = {};
          const countryIdToFedTeamName = {};
          const countryDropdown = countries.map(c => {
            countryIdToName[c.countryId] = c.countryName;
            countryIdToCode[c.countryId] = c.countryCode;
            countryIdToDialCode[c.countryId] = c.dialCode;
            countryIdToFedTeamName[c.countryId] = c.fedTeamName;
            return {
              value: c?.countryId,
              label: c?.countryName,
              code: c?.countryCode?.toLowerCase()
            };
          });
          const fedDropdown = countries
            .sort((a, b) => a?.fedTeamName?.localeCompare(b?.fedTeamName))
            .map(c => {
              return {
                value: c.countryId,
                label: c.fedTeamName,
                code: c?.countryCode?.toLowerCase()
              };
            });

          countryDispatch({ type: "setCountries", countries: countries });
          countryDispatch({
            type: "initialize",
            countryDropdown,
            countryIdToName,
            countryIdToCode,
            countryIdToDialCode,
            fedDropdown,
            countryIdToFedTeamName
          });
        })
        .catch(response => showAlert(`${response.toString()}, while loading countries`, "danger"));
    }
  }, [selectedYear, countryDispatch, showAlert]);
  return <div>{children}</div>;
};

ContextProviderInitializer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default ContextProviderInitializer;
