import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import ProfileContext from "../../../contexts/ProfileContext";
import WBCProfileApi from "../../../httpClients/WBCProfileApi";
import WBCDataTable from "../../data-table/WBCDataTable";
import ProfileHistoryDeleteFormatter from "./ProfileHistoryDeleteFormatter";
import ProfileHistoryDescriptionFormatter from "./ProfileHistoryDescriptionFormatter";
import ProfileHistoryIconFormatter from "./ProfileHistoryIconFormatter";
import ProfileHistoryActionDateFormatter from "./ProfileHistoryActionDateFormatter";
import TableSortFilterContext from "../../../contexts/TableSortFilterContext";

const HistoryTable = styled(WBCDataTable)`
  .react-grid-Viewport {
    height: ${props => `${props.viewportHeight}px`};
  }
`;

const columns = [
  {
    key: "actionDate",
    name: "Date",
    width: 100,
    sortable: true,
    formatter: ProfileHistoryActionDateFormatter
  },
  {
    key: "fedTeamName",
    name: "Fed",
    width: 230,
    sortable: true
  },
  {
    key: "hasDocuments",
    name: "",
    sortable: true,
    width: 55,
    formatter: ProfileHistoryIconFormatter,
    getRowMetaData: row => row
  },
  {
    key: "description",
    name: "Description",
    width: 440,
    sortable: true,
    formatter: ProfileHistoryDescriptionFormatter
  },
  {
    key: "userEmail",
    name: "User",
    sortable: true,
    width: 250
  },
  {
    key: "",
    name: ""
  },
  {
    key: "playerHistoryId",
    name: "",
    width: 55,
    formatter: ProfileHistoryDeleteFormatter
  }
];

const sortHistory = (col, dir, rows) => {
  const filters = [r => _.toLower(r[col])];
  switch (dir) {
    case "ASC":
      return _.sortBy(rows, filters);
    case "DESC":
      return _.sortBy(rows, filters).reverse();
    default:
      return _.sortBy(rows, [r => r.actionDateAsString]);
  }
};

const PlayerHistoryTable = params => {
  // hooks
  const profileContext = useContext(ProfileContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const [filteredPlayerHistory, setFilteredPlayerHistory] = useState([]);
  const [sortFilter, setSortFilter] = useState({
    dir: "DESC",
    col: "actionDate"
  });

  // variables
  const { profileId } = params;
  const { currentlySelectedTeam, profileHistory } = profileContext.state;

  // functions
  const filterPlayerHistory = useCallback(() => {
    let filtered =
      !currentlySelectedTeam?.fedTeamId || currentlySelectedTeam?.fedTeamId === -1
        ? profileHistory
        : profileHistory.filter(ph => ph.fedTeamId === currentlySelectedTeam.fedTeamId);
    return sortHistory(
      sortFilter.col,
      sortFilter.dir,
      filtered.map(ph => ({
        ...ph,
        actionDate: ph.actionDate?.length ? Date.parse(ph.actionDate) : null
      }))
    );
  }, [profileHistory, currentlySelectedTeam, sortFilter]);

  // effects
  useEffect(() => {
    if (!profileHistory) {
      WBCProfileApi.getAllTeamsPlayerHistoryById(profileId).then(playerHistoryById => {
        profileContext.dispatch({
          type: "setProfileHistory",
          value: playerHistoryById
        });
      });
    } else {
      setFilteredPlayerHistory(filterPlayerHistory());
    }
  }, [currentlySelectedTeam, profileId, filterPlayerHistory, profileHistory, profileContext]);

  return (
    <HistoryTable
      useBCR
      columns={columns}
      enableRowSelect={null}
      rowScrollTimeout={null}
      headerRowHeight={34}
      minHeight={Math.min(35 * filteredPlayerHistory.length + 55, 736)}
      viewportHeight={Math.min(35 * filteredPlayerHistory.length + 20, 700)}
      data={filteredPlayerHistory}
      sortFunc={(col, dir) => {
        setSortFilter({ col, dir });
        tableSortFilterContext.dispatch({
          type: "setSortFilters",
          sortFilters: { col, dir }
        });
      }}
      sortColumn={sortFilter.col}
      sortDirection={sortFilter.dir}
    />
  );
};

export default PlayerHistoryTable;
