import React, { useReducer } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import CountryContext from "../contexts/CountryContext";

const initialState = {
  countries: [],
  countryDropdown: [],
  fedDropdown: [],
  countryIdToName: {},
  countryIdToCode: {},
  countryIdToDialCode: {},
  countryIdToFedTeamName: {}
};

const CountryContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "initialize":
        return {
          ...state,
          countryDropdown: _.sortBy(action.countryDropdown, c => c.label),
          fedDropdown: _.sortBy(action.fedDropdown, c => c.label),
          countryIdToName: action.countryIdToName,
          countryIdToCode: action.countryIdToCode,
          countryIdToDialCode: action.countryIdToDialCode,
          countryIdToFedTeamName: action.countryIdToFedTeamName
        };
      case "setCountries":
        return { ...state, countries: _.sortBy(action.countries, c => c.label) };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <CountryContext.Provider value={{ state, dispatch }}>{children}</CountryContext.Provider>;
};

CountryContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default CountryContextProvider;
