import styled from "styled-components";

export const EmailNotificationsContainer = styled.div.attrs(() => ({
  className: "d-flex flex-column h-100 w-100 p-4"
}))`
  overflow: auto;
`;

export const FiltersContainer = styled.div.attrs(() => ({
  className: "d-flex row justify-content-start align-items-center flex-row mx-2 my-1"
}))`
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const SendContainer = styled.div.attrs(() => ({
  className: "d-flex row justify-content-start flex-row mx-2 my-3"
}))``;

export const SendContents = styled.div.attrs(() => ({
  className: "d-flex justify-content-center"
}))`
  gap: 0.5rem;
  font-size: 0.9rem;
`;

export const Filter = styled.div.attrs(() => ({
  className: "col-sm pl-0"
}))`
  @media (max-width: 767px) {
    margin-bottom: 0.75rem;
  }
`;
export const YearFilter = styled.div`
  width: 10rem;
  margin-right: 1rem;
`;
