import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import WBCCollapse from "../elements/WBCCollapse";
import RosterContext from "../../contexts/RosterContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import Infield from "./final/Infield";
import Outfield from "./final/Outfield";
import Pitchers from "./final/Pitchers";
import Catchers from "./final/Catchers";
import AuthContext from "../../contexts/AuthContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import FinalizedStatusContext from "../../contexts/FinalizedStatusContext";
import TournamentTypeContext from "../../contexts/TournamentTypeContext";
import ExportApi from "../../httpClients/ExportApi";
import FileHelper from "../../httpClients/FileHelper";
import { FinalizeWrapper, FinalizeButton } from "../roster/RosterStyles";
import { FINAL_ROSTER } from "../../constants/RosterListConstants";
import { useAlert } from "../hooks/useAlert";
import useDownloadFile from "../hooks/useDownloadFile";
import { MAIN_ID, QUALIFYING, QUALIFYING_ID } from "../../constants/TournamentTypeConstants";

const Container = styled.div`
  margin-bottom: 18px;
`;

const Text = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};
`;

const RosterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EXCEL = "Excel";
const PDF = "PDF";

const FinalRoster = () => {
  // context(s)
  const authContext = useContext(AuthContext);
  const rosterContext = useContext(RosterContext);
  const { selectedWbcYear, selectedYear } = useContext(WBCYearsContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const globalModalContext = useContext(GlobalModalContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);
  const tournamentType = useContext(TournamentTypeContext);

  // variable(s)
  const { dispatch: rosterDispatch } = rosterContext;
  const { final, finalIsOpen } = rosterContext.state;
  const { isBOCadmin, isFedAdmin, isTeamCoordinator } = authContext.state;
  const { team } = selectedTeamContext.state;
  const { finalized } = finalizedStatusContext.state;
  const size = Object.values(final).reduce((pv, c) => pv + c.length, 0);
  const settings = (selectedWbcYear && selectedWbcYear[`${tournamentType}TournamentSettings`]) || null;

  // hook(s)
  const showAlert = useAlert();
  const downloadFile = useDownloadFile();

  const canEdit = isBOCadmin || isFedAdmin || isTeamCoordinator;
  const rosterType = FINAL_ROSTER;

  // function(s)
  const finalizeRoster = () => {
    globalModalContext.dispatch({
      type: "openFinalizeRosterModal",
      headerText: `Finalize ${rosterType}`,
      content: { team, prefix: tournamentType, rosterType }
    });
  };

  const exportExcel = () => {
    ExportApi.exportFinalRoster(selectedYear, team.teamId, tournamentType, (url, headers) => ({ url, headers }))
      .then(data => {
        const test = new Blob([data.url], { type: data.headers["content-type"] });
        const url = window.URL.createObjectURL(test);
        FileHelper.downloadFile(url, data.headers);
      })
      .catch(() => {
        showAlert("Error exporting final roster to Excel", "danger");
      });
  };

  const exportPDF = () => {
    const tournamentTypeId = tournamentType === QUALIFYING ? QUALIFYING_ID : MAIN_ID;
    ExportApi.exportFinalRosters(selectedYear, tournamentTypeId, team.teamId)
      .then((res, headers) => {
        downloadFile(res, headers);
      })
      .catch(() => {
        showAlert("Error exporting final roster to PDF", "danger");
      });
  };

  const clickedFinalCollapse = isOpen => {
    rosterDispatch({
      type: "setFinalIsOpen",
      isOpen
    });
  };

  return (
    settings && (
      <Container>
        <WBCCollapse
          exportable
          exportableMenu={[
            { label: EXCEL, onClick: () => exportExcel() },
            { label: PDF, onClick: () => exportPDF() }
          ]}
          title={`Final (${size})`}
          subTitle={`${settings.rosterNumbers.finalRosterMax} player max`}
          startsOpen={finalIsOpen}
          exportFn={exportExcel}
          openCloseCallback={clickedFinalCollapse}
        >
          {canEdit && <Text>Drag players into position slots from the provisional roster</Text>}
          <RosterContainer>
            <Catchers settings={settings.positions} />
            <Pitchers settings={settings.positions} />
            <Infield settings={settings.positions} />
            <Outfield settings={settings.positions} />
          </RosterContainer>
        </WBCCollapse>
        {(isBOCadmin || isFedAdmin) && !finalized ? (
          <FinalizeWrapper>
            <FinalizeButton
              onClick={event => {
                event.stopPropagation();
                finalizeRoster();
              }}
            >
              Finalize {rosterType}
            </FinalizeButton>
          </FinalizeWrapper>
        ) : null}
      </Container>
    )
  );
};

export default FinalRoster;
