import Moment from "moment";
import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import PositionConstants from "../../constants/PositionConstants";
import { QUALIFYING } from "../../constants/TournamentTypeConstants";
import FinalizedStatusContext from "../../contexts/FinalizedStatusContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import RosterContext from "../../contexts/RosterContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import TournamentFormContext from "../../contexts/TournamentFormContext";
import TournamentTypeContext from "../../contexts/TournamentTypeContext";
import InterestListContext from "../../contexts/InterestListContext";
import MainRosterApi from "../../httpClients/MainRosterApi";
import QualifyingRosterApi from "../../httpClients/QualifyingRosterApi";
import WBCTeamApi from "../../httpClients/WBCTeamApi";
import WBCContent from "../elements/WBCContent";
import { useAlert } from "../hooks/useAlert";
import { useError } from "../hooks/useError";
import { useFinalizedStatus } from "../hooks/useFinalizedStatus";
import AvailableRoster from "./AvailableRoster";
import FinalRoster from "./FinalRoster";
import ProvisionalRoster from "./ProvisionalRoster";
import { sortPlayers } from "./RosterSortHelper";
import { formatRosterDate } from "../../utils/DateUtils";

const FinalizeWrapper = styled.div`
  margin-bottom: 16px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
`;

const FinalizedText = styled.span`
  display: inline-block;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.black};
  margin-right: 6px;
`;

const SharedRosterForm = () => {
  // context(s)
  const rosterContext = useContext(RosterContext);
  const globalModalContext = useContext(GlobalModalContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);
  const interestContext = useContext(InterestListContext);
  const tournamentType = useContext(TournamentTypeContext);

  // variable(s)
  const { isOpen } = globalModalContext.state;
  const { teams } = selectedTeamContext.state;
  const { teamId: fedTeamId, finalizedStatus } = selectedTeamContext.state.team;
  const { dispatch: globalDispatch } = globalModalContext;
  const { dispatch: selectedDispatch } = selectedTeamContext;
  const { dispatch: navigationDispatch } = tournamentFormContext;
  const { finalized } = finalizedStatusContext.state;
  const {
    dispatch: rosterDispatch,
    state: { provisional, final, uniformUpdates }
  } = rosterContext;
  const { dispatch: interestDispatch } = interestContext;

  // function(s)
  const showAlert = useAlert();
  const showErrors = useError();

  // load roster
  const loadRoster = useCallback(() => {
    const api = tournamentType === QUALIFYING ? QualifyingRosterApi : MainRosterApi;
    if (fedTeamId) {
      // load rosters
      const promises = [
        api.getAvailableRoster(fedTeamId),
        api.getProvisionalRoster(fedTeamId),
        api.getFinalRoster(fedTeamId)
      ];

      Promise.all(promises).then(([available, provisional, final]) => {
        const { players: finalRoster } = final;
        const provisionalWithFinal = provisional.players.map(p => ({ ...p, inFinalRoster: false })).concat(finalRoster);

        rosterDispatch({
          type: "setRoster",
          available: sortPlayers("lastName", "ASC", available),
          provisional: sortPlayers("lastName", "ASC", provisionalWithFinal),
          final: {
            catchers: sortPlayers(
              "positionCategoryIndex",
              "ASC",
              finalRoster.filter(p => p.positionCategory === PositionConstants.PLAYER.CATCHER)
            ),
            pitchers: sortPlayers(
              "positionCategoryIndex",
              "ASC",
              finalRoster.filter(p => p.positionCategory === PositionConstants.PLAYER.PITCHER)
            ),
            infield: sortPlayers(
              "positionCategoryIndex",
              "ASC",
              finalRoster.filter(p => p.positionCategory === PositionConstants.PLAYER.INFIELD)
            ),
            outfield: sortPlayers(
              "positionCategoryIndex",
              "ASC",
              finalRoster.filter(p => p.positionCategory === PositionConstants.PLAYER.OUTFIELD)
            )
          }
        });
      });
    }
  }, [tournamentType, fedTeamId, rosterDispatch]);

  // on save
  const onSave = useCallback(
    skipSuccessAlert => {
      // save variables
      const { catchers, pitchers, infield, outfield } = final;
      const rosterUpdates = {
        provisionalRoster: playersToRosterUpdates(provisional.players.filter(p => !p.inFinalRoster)),
        catchers: playersToRosterUpdates(catchers),
        pitchers: playersToRosterUpdates(pitchers),
        infield: playersToRosterUpdates(infield),
        outfield: playersToRosterUpdates(outfield)
      };

      const uniUpdates = Object.entries(uniformUpdates).map(uu => {
        const [profileId, sizes] = uu;
        return {
          profileId,
          uniformNumber: sizes.uniformNumber,
          pants: sizes.pants,
          jersey: sizes.jersey,
          hat: sizes.hat
        };
      });

      const api = tournamentType === QUALIFYING ? QualifyingRosterApi : MainRosterApi;

      const requests = [api.updateTournamentRoster(fedTeamId, rosterUpdates)];

      return new Promise((resolve, reject) => {
        Promise.all(requests)
          .then(() => {
            interestDispatch({
              type: "setRosterSaved",
              rosterSaved: true
            });
            api.updateUniforms(fedTeamId, uniUpdates).then(() => {
              if (skipSuccessAlert !== true) {
                showAlert("Saved");
              }
              resolve();
            });
          })
          .catch(({ response }) => {
            /**
             * BOC Admins won't get the roster finalized error.
             * any errors that come here should be unoverridable errors
             */
            const closeCallback = message => {
              return () => {
                if (message.indexOf("Roster already finalized") === -1) return;
                WBCTeamApi.getFederationTeamByTeamId(fedTeamId).then(({ finalizedStatus }) => {
                  const index = teams.findIndex(t => t.teamId === fedTeamId);
                  const team = { ...teams[index], finalizedStatus };
                  selectedDispatch({
                    type: "setTeams",
                    teams: Object.assign([...teams], { [index]: team }),
                    team
                  });
                  loadRoster();
                });
              };
            };

            showErrors(response.data.message, null, closeCallback(response.data.message));
            reject();
          });
      });
    },
    [
      tournamentType,
      provisional,
      final,
      uniformUpdates,
      fedTeamId,
      teams,
      showAlert,
      showErrors,
      loadRoster,
      interestDispatch,
      selectedDispatch
    ]
  );

  const onWarning = useCallback(
    afterSaveFn => {
      return new Promise((resolve, reject) => {
        if (finalized && !isOpen) {
          const saveFn = () => {
            onSave(!!afterSaveFn).then(() => {
              globalDispatch({ type: "closeModal" });
              if (afterSaveFn) {
                afterSaveFn();
              }
              resolve();
            });
          };

          globalDispatch({
            type: "openWarningModal",
            content: "This roster has already been finalized. Do you still wish to save?",
            saveFn
          });
        } else {
          onSave(!!afterSaveFn).then(() => {
            if (afterSaveFn) {
              afterSaveFn();
            }
            resolve();
          });
        }
      });
    },
    [globalDispatch, finalized, isOpen, onSave]
  );

  const playersToRosterUpdates = players => {
    const rosterUpdates = [];
    players.forEach((player, i) => {
      rosterUpdates.push({ profileId: player.profileId, playerPositionCategoryIndex: i });
    });
    return rosterUpdates;
  };

  // effects
  // load roster
  useEffect(loadRoster, [loadRoster]);

  // set on save functionality
  useEffect(() => navigationDispatch({ type: "setOnSave", onSave: onWarning }), [navigationDispatch, onWarning]);

  // set on cancel
  useEffect(() => navigationDispatch({ type: "setOnCancel", onCancel: loadRoster }), [navigationDispatch, loadRoster]);

  // get finalized status
  useFinalizedStatus(tournamentType);

  return (
    <WBCContent>
      {finalized && finalizedStatus ? (
        <FinalizeWrapper>
          <FinalizedText>Finalized</FinalizedText>
          {formatRosterDate(finalizedStatus[`${tournamentType}RosterFinalizedDate`])} by{" "}
          {finalizedStatus[`${tournamentType}RosterFinalizedUser`]}
        </FinalizeWrapper>
      ) : null}
      <AvailableRoster tournamentType={tournamentType} />
      <ProvisionalRoster tournamentType={tournamentType} />
      <FinalRoster tournamentType={tournamentType} />
    </WBCContent>
  );
};

export default SharedRosterForm;
