import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormInput } from "best-common-react";
import WBCCollapse from "../../elements/WBCCollapse";
import WBCHorizontalFieldWrapper from "../../elements/WBCHorizontalFieldWrapper";
import WBCHorizontalFieldInput from "../../elements/WBCHorizontalFieldInput";
import WBCAccessControl from "../../protected/WBCAccessControl";
import DisabledInputStyle from "../../elements/DisabledInputStyle";
import WBCRadioButton from "../../elements/WBCRadioButton";
import FeatureConstants from "../../../constants/FeatureConstants";
import FeatureToggler from "../../elements/FeatureToggler";
import { WBCPrimaryButton } from "../../elements/WBCPrimaryButton";
import ExportFinalRostersModal from "./ExportFinalRostersModal";

const GeneralRosterContainer = styled.div`
  padding: 24px;
`;

const RadioButtonContainer = styled.div.attrs(() => ({
  className: "d-flex align-items-center"
}))`
  &&& {
    font-size: 14px;
  }
`;

const ExportFinalRostersButton = styled(WBCPrimaryButton)`
  display: flex;
  align-items: center;
  width: auto;
  padding: 0 0.75rem;
`;

const GeneralRosterSettings = ({ settings, changeFn, canEdit }) => {
  const [isExportFinalRosterModalOpen, setIsExportFinalRosterModalOpen] = useState(false);

  const openExportFinalRostersModal = () => {
    setIsExportFinalRosterModalOpen(true);
  };

  return (
    <GeneralRosterContainer>
      <WBCCollapse title="General Roster Settings">
        <WBCAccessControl
          input={isDisabled => (
            <div className={"row"}>
              <div className={"col-lg-4"}>
                <WBCHorizontalFieldWrapper label="Interest List Max" required={!isDisabled || !canEdit}>
                  <WBCHorizontalFieldInput label="Players">
                    <FormInput
                      type="number"
                      name="interestListMax"
                      value={settings.interestListMax}
                      editable
                      isRequired={!isDisabled}
                      onChangeFn={value => changeFn("ROSTER_SETTINGS", { ...settings, interestListMax: value })}
                      isDisabled={isDisabled || !canEdit}
                      inputStyle={DisabledInputStyle}
                    />
                  </WBCHorizontalFieldInput>
                </WBCHorizontalFieldWrapper>
              </div>
              <div className={"col-lg-4"}>
                <FeatureToggler feature={FeatureConstants.MAIN_TOURNAMENT}>
                  <WBCHorizontalFieldWrapper
                    label="Add Available Players to"
                    required={!isDisabled || !canEdit}
                    width={240}
                  ></WBCHorizontalFieldWrapper>
                  <RadioButtonContainer>
                    <div className="mr-3">
                      <WBCRadioButton
                        checked={settings.tournamentTypeId === 1}
                        onClick={() => {
                          changeFn("ROSTER_SETTINGS", { ...settings, tournamentTypeId: 1 });
                        }}
                        label="Qualifying Roster"
                        bold={false}
                        isDisabled={isDisabled || !canEdit}
                      />
                    </div>
                    <div className="mr-3">
                      <WBCRadioButton
                        checked={settings.tournamentTypeId === 2}
                        onClick={() => {
                          changeFn("ROSTER_SETTINGS", { ...settings, tournamentTypeId: 2 });
                        }}
                        label="Main Tournament Roster"
                        bold={false}
                        isDisabled={isDisabled || !canEdit}
                      />
                    </div>
                  </RadioButtonContainer>
                </FeatureToggler>
              </div>
              <div className={"col-lg-4"}>
                <div className="d-flex justify-content-end mr-4">
                  <ExportFinalRostersButton onClick={openExportFinalRostersModal}>
                    Export Final Rosters
                  </ExportFinalRostersButton>
                </div>
              </div>
            </div>
          )}
        />
      </WBCCollapse>
      <ExportFinalRostersModal isOpen={isExportFinalRosterModalOpen} setIsOpen={setIsExportFinalRosterModalOpen} />
    </GeneralRosterContainer>
  );
};

GeneralRosterSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  changeFn: PropTypes.func.isRequired,
  canEdit: PropTypes.bool
};

export default GeneralRosterSettings;
