import React from "react";
import { Route, Router, Switch } from "react-router";
import { ThemeProvider } from "styled-components";
import Theme from "./Theme";
import "./App.css";
import RouteConstants from "./constants/RouteConstants";
import LoginContainer from "./containers/LoginContainer";
import HomeContainer from "./containers/HomeContainer";
import TournamentContainer from "./containers/TournamentContainer";
import InterestListContainer from "./containers/InterestListContainer";
import ProfileContainer from "./containers/ProfileContainer";
import SettingsContainer from "./containers/SettingsContainer";
import Authentication from "./components/auth/Authentication";
import PrivateRoute from "./components/protected/PrivateRoute";
import WBCNavigation from "./components/navigation/WBCNavigation";
import WBCFooter from "./components/navigation/WBCFooter";
import HttpInterceptor from "./httpClients/HttpInterceptor";
import MobileNavContextProvider from "./providers/MobileNavContextProvider";
import InvalidPermissionsContainer from "./containers/InvalidPermissionsContainer";
import ContextProviderWrapper from "./providers/wrapper/ContextProviderWrapper";
import WBCAlerts from "./components/alerts/WBCAlerts";
import history from "./history/history";
import LoadingContextProvider from "./providers/LoadingContextProvider";
import UsersContainer from "./containers/UsersContainer";
import GlobalModal from "./components/global-modal/GlobalModal";
import DragDropContextProvider from "./providers/DragDropContextProvider";
import PlayerEligibilityContainer from "./containers/PlayerEligibilityContainer";
import OktaLogin from "./components/login/OktaLogin";
import ClubCommContainer from "./containers/ClubCommContainer";
import EventReportContainer from "./containers/EventReportContainer";
import FeatureConstants from "./constants/FeatureConstants";
import ClubCommContextProviderWrapper from "./providers/wrapper/clubcomm/ClubCommContextProviderWrapper";
import ClubCommFilterManager from "./components/clubcomm/ClubCommFilterManager";
import MLBPlayersContainer from "./containers/MLBPlayersContainer";
import EmailNotificationsContainer from "./containers/EmailNotificationsContainer";
import FinalRosterExportContainer from "./containers/FinalRosterExportContainer";

const App = () => (
  <div className="App">
    <Router history={history}>
      <Authentication history={history}>
        <LoadingContextProvider>
          <HttpInterceptor>
            <ThemeProvider theme={Theme}>
              <div className="wbc-body">
                <DragDropContextProvider>
                  <MobileNavContextProvider>
                    <ContextProviderWrapper>
                      <ClubCommContextProviderWrapper>
                        <OktaLogin>
                          <WBCNavigation />
                          <WBCAlerts />
                          <GlobalModal />
                          <ClubCommFilterManager />
                          <Switch>
                            <PrivateRoute exact path={RouteConstants.BASE} component={HomeContainer} />
                            <PrivateRoute
                              exact
                              path={RouteConstants.FEDERATION_TEAMS}
                              component={TournamentContainer}
                            />
                            <PrivateRoute exact path={RouteConstants.INTEREST_LIST} component={InterestListContainer} />
                            <PrivateRoute path={RouteConstants.PROFILE} component={ProfileContainer} />
                            <PrivateRoute
                              exact
                              whiteBackground
                              path={RouteConstants.SETTINGS}
                              component={SettingsContainer}
                            />
                            <PrivateRoute
                              exact
                              whiteBackground
                              path={RouteConstants.USERS}
                              component={UsersContainer}
                            />
                            <PrivateRoute
                              exact
                              whiteBackground
                              path={RouteConstants.PLAYER_ELIGIBILITY}
                              component={PlayerEligibilityContainer}
                            />
                            <PrivateRoute exact path={RouteConstants.INVALID} component={InvalidPermissionsContainer} />
                            <PrivateRoute
                              exact
                              whiteBackground
                              path={RouteConstants.CLUB_COMM}
                              component={ClubCommContainer}
                              feature={FeatureConstants.CLUB_COMM}
                            />
                            <PrivateRoute
                              path={RouteConstants.EVENT_REPORT}
                              whiteBackground
                              component={EventReportContainer}
                              feature={FeatureConstants.CLUB_COMM}
                            />
                            <PrivateRoute path={RouteConstants.MLB_PLAYERS} component={MLBPlayersContainer} />
                            <PrivateRoute
                              exact
                              path={RouteConstants.EMAIL_NOTIFICATIONS}
                              whiteBackground
                              component={EmailNotificationsContainer}
                            />
                            <Route exact path={RouteConstants.LOGIN} component={LoginContainer} />
                            <Route exact path={RouteConstants.FINAL_ROSTER} component={FinalRosterExportContainer} />
                          </Switch>
                        </OktaLogin>
                      </ClubCommContextProviderWrapper>
                    </ContextProviderWrapper>
                    <WBCFooter />
                  </MobileNavContextProvider>
                </DragDropContextProvider>
              </div>
            </ThemeProvider>
          </HttpInterceptor>
        </LoadingContextProvider>
      </Authentication>
    </Router>
  </div>
);

export default App;
