import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FinalRosterExportContext from "../contexts/FinalRosterExportContext";
import RosterApi from "../httpClients/RosterApi";

const INITIAL_STATE = {
  rostersByFed: null,
  tournamentType: null,
  isLoading: false
};

const FinalRosterExportProvider = ({ children }) => {
  // get year from URL
  const params = new URLSearchParams(window.location.search);
  const year = params.get("year");
  const tournamentTypeParam = params.get("tournamentType");
  const fedTeam = params.get("fedTeam");

  const [rostersByFed, setRostersByFed] = useState(INITIAL_STATE.rostersByFed);
  const [tournamentType, setTournamentType] = useState(INITIAL_STATE.tournamentType);
  const [isLoading, setIsLoading] = useState(INITIAL_STATE.isLoading);

  useEffect(() => {
    setTournamentType(tournamentTypeParam);
  }, [tournamentTypeParam]);

  useEffect(() => {
    if (year && tournamentType) {
      setIsLoading(true);
      RosterApi.getFinalRostersByFed(year, tournamentType, fedTeam)
        .then(response => {
          setRostersByFed(response?.federationRosters);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [year, tournamentType, fedTeam]);

  return (
    <FinalRosterExportContext.Provider
      value={{
        rostersByFed,
        setRostersByFed,
        year,
        isLoading
      }}
    >
      {children}
    </FinalRosterExportContext.Provider>
  );
};

FinalRosterExportProvider.propTypes = {
  children: PropTypes.object
};

export default FinalRosterExportProvider;
