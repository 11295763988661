export default {
  BASE: "/",
  INVALID: "/invalid",
  LOGIN: "/login",
  FEDERATION_TEAMS: "/federationteams",
  INTEREST_LIST: "/interestlist",
  QUALIFYING_ROSTER: "/qualifyingroster",
  SETTINGS: "/settings",
  USERS: "/users",
  PROFILE: "/federationteam/:fedTeamId/:profileType/profile/:playerId",
  NEW_PROFILE_SUFFIX: "new",
  PLAYER: "player",
  STAFF: "staff",
  PLAYER_ELIGIBILITY: "/eligibility",
  CLUB_COMM: "/clubcomm",
  EVENT_REPORT: "/clubcomm/:teamId/report/:eventReportId",
  MLB_PLAYERS: "/mlbplayers",
  EMAIL_NOTIFICATIONS: "/emailnotifications",
  FINAL_ROSTER: "/finalrosterexport"
};
