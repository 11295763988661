import BaseApi from "./BaseApi";

export default class ChronicConditionsApi extends BaseApi {
  constructor() {
    if (new.target === ChronicConditionsApi) {
      throw new TypeError("Cannot construct ChronicConditionsApi instances directly");
    }
  }

  static updateChronicConditionsStatus = (chronicConditionIds, insuranceStatusId) =>
    this.put(
      `chronicconditions/update?chronicConditionIds=${chronicConditionIds}&insuranceStatusId=${insuranceStatusId}`
    );
  static getChronicConditionsByYear = year => this.get(`chronicconditions?wbcYear=${year}`);
  static getAllInsuranceStatuses = () => this.get(`chronicconditions/insuranceStatuses`);
  static getChronicConditionPlayerOptions = year => this.get(`chronicconditions/playerOptions?wbcYear=${year}`);
  static addChronicConditionForPlayers = (wbcYear, ebisIds) =>
    this.post(`chronicconditions/save?wbcYear=${wbcYear}&ebisIds=${ebisIds.join(",")}`);
}
