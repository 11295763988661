import React from "react";
import FinalRosterExportDashboard from "../components/final-roster-export/FinalRosterExportDashboard";
import styled from "styled-components";

// make entire page background white
export const FinalRosterHolder = styled.div`
  background-color: white;
`;

const FinalRosterExportContainer = () => {
  return (
    <FinalRosterHolder>
      <FinalRosterExportDashboard />
    </FinalRosterHolder>
  );
};

export default FinalRosterExportContainer;
