import React, { useReducer, useState, useEffect } from "react";
import { AlertConstants } from "best-common-react";
import PropTypes from "prop-types";
import ChronicConditionsContext from "../contexts/ChronicConditionsContext";
import ChronicConditionsApi from "../httpClients/ChronicConditionsApi";
import { useAlert } from "../components/hooks/useAlert";
import { sortPlayers } from "../components/roster/RosterSortHelper";

const DEFAULT_INSURANCE_STATUS_FILTER = { label: "All Insurance Statuses", value: null };

const INITIAL_STATE = {
  playerConditions: [],
  insuranceStatusFilter: DEFAULT_INSURANCE_STATUS_FILTER,
  insuranceStatuses: [],
  insuranceActions: [],
  selectedPlayerEbisIds: [],
  insuranceStatusSelected: null,
  nameSearchFilter: null,
  sortFilters: {
    col: "lastName",
    dir: "ASC"
  }
};

const ChronicConditionsContextProvider = ({ children }) => {
  const [playerConditions, setPlayerConditions] = useState(INITIAL_STATE.playerConditions);
  const [insuranceStatusFilter, setInsuranceStatusFilter] = useState(INITIAL_STATE.insuranceStatusFilter);
  const [insuranceStatuses, setInsuranceStatuses] = useState(INITIAL_STATE.insuranceStatuses);
  const [selectedChronicConditionIds, setSelectedChronicConditionIds] = useState(INITIAL_STATE.selectedPlayerEbisIds);
  const [insuranceActions, setInsuranceActions] = useState(INITIAL_STATE.insuranceActions);
  const [insuranceStatusSelected, setInsuranceStatusSelected] = useState(INITIAL_STATE.insuranceStatusSelected);
  const [nameSearchFilter, setNameSearchFilter] = useState(INITIAL_STATE.nameSearchFilter);
  const [sortFilters, setSortFilters] = useState(INITIAL_STATE.sortFilters);
  const [filteredPlayerConditions, setFilteredPlayerConditions] = useState([]);
  const [actionSelected, setActionSelected] = useState(null);
  const [updatePlayers, setUpdatePlayers] = useState(false);
  const showAlert = useAlert();

  useEffect(() => {
    setFilteredPlayerConditions([
      ...playerConditions.filter(
        player =>
          (!nameSearchFilter ||
            player?.firstName?.toLowerCase().includes(nameSearchFilter?.toLowerCase()) ||
            player?.lastName?.toLowerCase().includes(nameSearchFilter?.toLowerCase())) &&
          (insuranceStatusFilter.value === null || player.insuranceStatus === insuranceStatusFilter.label)
      )
    ]);
  }, [playerConditions, insuranceStatusFilter, nameSearchFilter]);

  useEffect(() => {
    if (!!insuranceStatusSelected) {
      const updatedActions = [...insuranceActions].map(action => {
        action.isActive = actionCanBePerformed(action, insuranceStatusSelected?.name);
        return action;
      });
      setInsuranceActions(updatedActions);
    } else {
      setInsuranceActions(
        [...insuranceActions].map(action => {
          action.isActive = false;
          return action;
        })
      );
    }
  }, [insuranceStatusSelected]);

  useEffect(() => {
    if (actionSelected?.isActive) {
      ChronicConditionsApi.updateChronicConditionsStatus(selectedChronicConditionIds, actionSelected.value)
        .then(() => {
          showAlert("Success", AlertConstants.TYPES.SUCCESS);
        })
        .catch(() => {
          showAlert("Error while updating player status", AlertConstants.TYPES.DANGER);
        })
        .finally(() => {
          setSelectedChronicConditionIds([]);
          setInsuranceStatusSelected(null);
          setUpdatePlayers(prev => !prev);
        });
    }
  }, [actionSelected]);

  useEffect(() => {
    const { col, dir } = sortFilters;
    setPlayerConditions(prev => sortPlayers(col, dir, [...prev], false));
  }, [sortFilters]);

  const actionCanBePerformed = (action, status) => {
    if (status === null) {
      return false;
    } else if (status === "Insurance Review") {
      switch (action.action) {
        case "Approve":
        case "Deny":
        case "Medical Review":
        case "Remove From List":
          return true;
        default:
          return false;
      }
    } else if (status === "Medical Review") {
      switch (action.action) {
        case "Approve":
        case "Deny":
          return true;
        default:
          return false;
      }
    }
    if (status === "Approved" || status === "Denied") {
      switch (action.action) {
        case "Remove From List":
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  const reducer = (state, action) => {
    switch (action.type) {
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <ChronicConditionsContext.Provider
      value={{
        state,
        dispatch,
        sortFilters,
        setSortFilters,
        playerConditions,
        setPlayerConditions,
        insuranceStatusFilter,
        setInsuranceStatusFilter,
        insuranceStatuses,
        setInsuranceStatuses,
        nameSearchFilter,
        setNameSearchFilter,
        filteredPlayerConditions,
        setFilteredPlayerConditions,
        selectedChronicConditionIds,
        setSelectedChronicConditionIds,
        insuranceActions,
        setInsuranceActions,
        insuranceStatusSelected,
        setInsuranceStatusSelected,
        actionSelected,
        setActionSelected,
        updatePlayers,
        setUpdatePlayers
      }}
    >
      {children}
    </ChronicConditionsContext.Provider>
  );
};

ChronicConditionsContextProvider.propTypes = {
  children: PropTypes.object
};

export default ChronicConditionsContextProvider;
