import styled from "styled-components";

const WBCFooter = styled.div.attrs(() => ({
  id: "wbc-footer"
}))`
  background: linear-gradient(
    to right,
    ${props => props.theme.wbcYellow} 25%,
    ${props => props.theme.wbcGreen} 25%,
    ${props => props.theme.wbcGreen} 50%,
    ${props => props.theme.wbcBlue} 50%,
    ${props => props.theme.wbcBlue} 75%,
    ${props => props.theme.wbcRed} 75%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6px;
  z-index: 1051;
`;

export default WBCFooter;
